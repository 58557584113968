import React, { useEffect, useState } from "react";
import policyText from "../privacy.txt";
import Markdown from "markdown-to-jsx";
import { Link } from "react-router-dom";
function PrivacyPolicy() {
  const [terms, setTerms] = useState("");
  useEffect(() => {
    const getTerms = async () => {
      try {
        const response = await fetch(policyText); // Fetching the imported file
        const text = await response.text();
        setTerms(text); // Setting the content in state
        // You can perform any operations here with 'text'
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    };

    getTerms();
  }, []);
  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "90%" }}>
      <h1 className="text-center">Privacy Policy</h1>
        <Markdown>{terms}</Markdown>
        <div className="border-t w-full" />
        <footer className="bg-white rounde shadow my-4 dark:bg-gray-800 w-full">
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="https://foodiyo.info/" className="hover:underline">
                Foodiyo™
              </a>
              . All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
              <li>
                <Link to={"/"} className="hover:underline me-4 md:me-6">
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="hover:underline me-4 md:me-6">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to={"/privacy_policy"}
                  className="hover:underline me-4 md:me-6"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to={"/terms_and_conditions"}
                  className="hover:underline me-4 md:me-6"
                >
                  {`Terms & Conditions`}
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
