import "./App.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import TermsAndConditions from "./pages/Terms";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
function App() {
  let routes = [
    { path: "/", element: <Home /> },
    { path: "/privacy_policy", element: <PrivacyPolicy /> },
    { path: "/terms_and_conditions", element: <TermsAndConditions /> },
    { path: "/about", element: <AboutUs /> },
    { path: "*", element: <Navigate to="/" replace /> },
  ];
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
