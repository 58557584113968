import React from "react";
import { Link } from "react-router-dom";
import new_logo from "../img/banner.png";
function Home() {
  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${new_logo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="flex flex-col w-full justify-end items-center"
    >
      <footer className="bg-white rounde shadow mx-4 dark:bg-gray-800 w-full">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a href="https://foodiyo.info/" className="hover:underline">
              Foodiyo™
            </a>
            . All Rights Reserved.
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
            <li>
              <Link to={"/about"} className="hover:underline me-4 md:me-6">
                About
              </Link>
            </li>
            <li>
              <Link
                to={"/privacy_policy"}
                className="hover:underline me-4 md:me-6"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to={"/terms_and_conditions"}
                className="hover:underline me-4 md:me-6"
              >
                {`Terms & Conditions`}
              </Link>
            </li>
            {/* <li>
              <Link to={"/privacy_policy"} className="hover:underline me-4 md:me-6">
                Contact
              </Link>
            </li> */}
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Home;
